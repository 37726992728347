@import './reset.scss';
#wrapper {
	float: left; width: 100%; display: flex; justify-content: center; 
	align-items: center; height: 100vh;
	#logo {
		width: 394px; height: 191px; 
		background: url('../images/logo.svg') center center no-repeat;
		background-size: cover;
	}
	footer {
		position: fixed; bottom: 10px; display: flex;
		grid-gap: 10px;
		a {
			color: #000; font-weight: 600; padding: 5px 0 5px 30px;
			&#btn-instagram {
				background: url('../images/ico-instagram.svg') left center no-repeat;
			}
			&#btn-facebook {
				background: url('../images/ico-facebook.svg') left center no-repeat;
			}
			&#btn-email {
				background: url('../images/ico-email.svg') left center no-repeat;
			}
			&#btn-phone {
				background: url('../images/ico-phone.svg') left center no-repeat;
			}
		}
	}
}

@media screen and (max-width: 980px) {
	body {
	  min-height: 100vh;
	  /* mobile viewport bug fix */
	  min-height: -webkit-fill-available;
	}

	html {
	  height: -webkit-fill-available;
	}
	#wrapper {
		min-height: 100vh;
		height: -webkit-fill-available;
	}
	#wrapper #logo {
		width: 263px; height: 127px; 
		background: url('../images/logo.svg') center center no-repeat;
	}
	#wrapper footer {
		flex-direction: column;
    	left: 10px; grid-gap: 5px;
    	a {
    		font-size: 10px;
    	}
	}
}