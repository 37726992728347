/*########################################
/#####RESET ELEMENTS
########################################*/
* {
  margin: 0; padding: 0; box-sizing: border-box;
}
ul {
  list-style: none; list-style-type: none;
}
ul li {
  float: left;
}
img, fieldset {
  border: none;
}
h1, h2, h3, h4, h5, h6 {
  font-weight: normal; margin-bottom: 10px;
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,600;1,300;1,600&display=swap');

/*########################################
/#####MAIN ELEMENTS
########################################*/
body {
  font-family: 'Open Sans', sans-serif;
  background: #FFF; color: #000; font-size: 14px; 
  font-weight: 400; line-height: 1.5em;
}
p {}